import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  :root {
    font-size: 16px;
    font-weight: 400;
  }

  html, body, #___gatsby, #layout-container {
    font-family: 'Prompt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
    background: #ffffff;
  }

  @media (max-width: 599px) {
    :root {
      font-size: 12px;
    }
  }
  @media (min-width: 600px) {
    :root {
      font-size: 13px;
    }
  }
  @media (min-width: 900px) {
    :root {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) {
    :root {
      font-size: 16px;
    }
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol, li {
    margin: 0;
    padding: 0;
  }

  #___gatsby {
    transition: background-color 0.3s ease;
  }

  #layout-container {
    position: relative;
  }

  .abh-momentum-scroll {
    height: 100vh;

    .scrollbar-thumb {
      background: black;
      opacity: 0.25;
    }
    .scrollbar-track {
      background: transparent !important;
    }
  }

  #check {
    display: none;
  }

  #abh-works {
  position: relative;

  .abh-work {
    height: 100vh;
    position: relative;

    .abh-work-item {
      cursor: pointer;
      display: block;
      width: auto;
      padding: 0;
      position: relative;
      padding: 0 180px;
    }

    .abh-work-item-grid {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      min-height: 100vh;
    }

    .abh-work-item-grid-right {
      max-width: 50%;
    }

    .abh-work-item-grid-left {
      margin: 0;
      max-width: none;
      text-align: left;
    }

    .abh-work-item-grid-header {
      display: block;
    }

    .abh-work-item-grid-text {
      display: block;
      margin: 30px 0 0 0;
      font-size: 2.58333vw;
      line-height: 133%;
      letter-spacing: -0.03em;
    }

    .abh-work-item-grid-header-tag {
      display: block;
      margin: 30px 0 0 0;
      color: #6f6f6f;
      font-size: 1.16667vw;
      letter-spacing: 0.04375em;
      line-height: 156%;
    }
  }
}

`
