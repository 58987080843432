import styled from "styled-components"

export const Container = styled.div``

export const LogoWrap = styled.div`
  position: fixed;
  z-index: 1000;
  top: 10px;
  left: 10px;
  opacity: 0;

  & > a {
    display: inline-block;
    padding: 20px 15px;
  }

  @media (max-width: 599px) {
    transform: rotate(90deg);
    transform-origin: 171% 48%;
  }
  @media (min-width: 600px) {
    height: auto;
  }
  @media (min-width: 900px) {
    height: 100vh;
  }
`
export const LetsTalkWrap = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  left: 10px;
  opacity: 0;

  & > div {
    padding: 20px 15px;
  }

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: block;
  }
  @media (min-width: 900px) {
    display: block;
  }
`

export const HamWrap = styled.div`
  position: fixed;
  z-index: 1000;
  top: 10px;
  right: 20px;
  opacity: 0;

  & > div {
    padding: 20px 15px;
    position: relative;
  }
`

export const Hamburger = styled.div`
  height: 25px;
  width: 25px;
  position: relative;
`

export const HamLine1 = styled.div`
  height: 2px;
  width: 20px;
  background: #000;
  position: absolute;
  top: 15px;
  left: 0;
`
export const HamLine2 = styled.div`
  height: 2px;
  width: 25px;
  background: #000;
  position: absolute;
  bottom: 15px;
  left: 0;
`
