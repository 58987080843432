import React, { useRef } from 'react';

import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "../../context/globalContext"

const Target: React.FC<any> = ({ children, cursorText, cursorColor, ...rest }) => {
  const dispatch = useGlobalDispatchContext();

  const onMouseEnter = () => {
    dispatch({ type: "CURSOR_TYPE", cursorType: 'hovered' })
    if (cursorColor) {
      dispatch({ type: "CURSOR_COLOR", cursorColor: cursorColor });
    }
    if (cursorText) {
      dispatch({ type: "CURSOR_TEXT", cursorText: cursorText });
    }
  }

  const onMouseLeave = () => {
    dispatch({ type: "CURSOR_TYPE", cursorType: false })
    if (cursorColor) {
      dispatch({ type: "CURSOR_COLOR", cursorColor: '#F5F5F5' });
    }
    if (cursorText) {
      dispatch({ type: "CURSOR_TEXT", cursorText: '' });
    }
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest}>
      {children}
    </div>
  );
};

export default Target;
