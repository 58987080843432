import styled from "styled-components"

export const FooterWrap = styled.div`
  padding: 50px 126px;

  @media (max-width: 599px) {
    padding: 0;
  }
  @media (min-width: 600px) {
    padding: 10px;
  }
  @media (min-width: 900px) {
    padding: 50px 126px;
  }
`

export const Container = styled.footer`
  background: #000;
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
`
export const TopSection = styled.div`
  padding: 50px 0;
  display: flex;
  align-items: center;
  margin: 0 50px;
  border-bottom: 1px solid #fff;

  .left {
    flex: 1;

    h1 {
      font-size: 3rem;
      font-weight: 500;
      text-align: center;
    }
  }
  .right {
    flex: 1;
    padding-left: 50px;
    p {
      max-width: 400px;
      line-height: 25px;
      font-size: 1rem;
      margin-bottom: 25px;
      font-weight: 200;
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
    padding: 40px 0;
    margin: 0 20px;
    text-align: center;

    .right {
      padding-left: 0;
      margin-top: 20px;
    }
  }
  @media (min-width: 600px) {
    flex-direction: column;
    padding: 40px 0;
    margin: 0 20px;
    text-align: center;

    .right {
      padding-left: 0;
      margin-top: 20px;
    }
  }
  @media (min-width: 900px) {
    flex-direction: row;
    padding: 50px 0;
    margin: 0 50px;
    text-align: left;
    .right {
      padding-left: 50px;
    }
  }
`

export const CenterSection = styled.div`
  flex: 1;
  padding: 30px 50px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px 10px;
  }
  @media (min-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px 10px;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    align-items:center;
    padding: 30px 50px 10px;
  }
`

export const AddressSection = styled.div`
  a {
    color: #FFFFFF;
    margin-top: 30px;
    display: block;
    color: rgba(255,255,255,.6);
    font-size: 14px;
    font-weight: 300;
  }
  @media (max-width: 599px) {
    margin-bottom: 30px;
  }
  @media (min-width: 600px) {
    margin-bottom: 30px;
  }
  @media (min-width: 900px) {
    margin-bottom: 0;
  }
`
export const NavigationSection = styled.div`
  display: flex;
`
export const Sitemap = styled.div`
  min-width: 170px;
`
export const SocialConnect = styled.div`
  min-width: 170px;
`

export const Logo = styled.div`
  margin-bottom: 20px;
`
export const Address = styled.address`
  max-width: 210px;
  color: rgba(255,255,255,.6);
  font-size: 14px;
  font-weight: 200;
  line-height: 157%;
  font-style: normal;
`

export const BottomSection = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 300;

  ul {
    list-style: none;

    li {
      float: left;
      padding: 0 15px;
      position: relative;
      cursor: pointer;

      a {
        color: #FFFFFF;
        text-decoration: none;
      }

      &:before {
        content: "";
        height: 2px;
        width: 2px;
        border-radius: 100%;
        background: #9a9a9a;
        position: absolute;
        right: 0;
        top: 50%;
      }

      &:last-child {
        padding-right: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
    height: auto;
    padding: 40px 50px;
    ul {
      display: none;
    }
  }
  @media (min-width: 600px) {
    flex-direction: column;
    height: auto;
    padding: 40px 50px;
    ul {
      display: none;
    }
  }
  @media (min-width: 900px) {
    flex-direction: row;
    height: 100px;
    padding: 40px 50px;
    ul {
      display: block;
    }
  }
`

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 400;
  color: #777676;
  margin-bottom: 10px;
`
export const NavItems = styled.ul`
  list-style: none;
`
export const Item = styled.li`
  font-size: 15px;
  font-weight: 200;
  padding: 7.5px 0;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #FFF;
  }
`
