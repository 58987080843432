import React, { useState } from "react"
import PropTypes from "prop-types"

import PageItems from './pageItems';
import MomentumScroll from './smooth-scroll';
import Footer from './footer';
import Navigation from './navigation';
import Cursor from './Cursor';

import { GlobalStyle } from "./../styles/global"

const Layout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(p => !p);
  }

  return (
    <>
      <GlobalStyle />
      <div id="layout-container">
        <PageItems toggleNav={toggleNav} />
        <Navigation navOpen={navOpen} toggleNav={toggleNav} />
        <MomentumScroll>
          {children}
          <Footer />
        </MomentumScroll>
      </div>
      <Cursor />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
