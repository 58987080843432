import styled, { css } from "styled-components"

export const Container = styled.button<any>`
  height: 48px;
  padding: 0 32px;
  border: 1px solid #000;
  ${({ invert, isActive }) =>
    !invert
      ? css`
          color: ${isActive ? "#fff" : "#000"};
          background: ${isActive ? "#000" : "#fff"};
          border-color: #000;
        `
      : css`
          color: ${isActive ? "#000" : "#000"};
          background: ${isActive ? "#fff" : "#fff"};
          border-color: #fff;
        `}
  box-shadow: none;
  border-radius: 48px;
  cursor: pointer;
  transition: all 200ms linear;
  outline: none;

  &:hover {
    background: #000;
    color: #fff;
  }
`
