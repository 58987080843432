import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

import Target from "./Cursor/target";
import PageTransition from "./page-transition";
import { Container, SideBar, SocialContainer, MenuContainer, Title, NavList } from "../styles/navigation";

const Navigation: React.FC<any> = ({ navOpen, toggleNav }) => {
  const containerRef = useRef<any>(null);
  const sidebarRef = useRef<any>(null);

  useEffect(() => {
    if (navOpen) {
      gsap.to(containerRef.current, {
        background: 'rgba(229, 229, 229, 0.75)',
        duration: 1,
      });
      gsap.to(sidebarRef.current, {
        x: '0',
        duration: 0.5,
      });
    } else {
      gsap.to(containerRef.current, {
        background: 'rgba(229, 229, 229, 0)',
        duration: 1,
      });
      gsap.to(sidebarRef.current, {
        x: '150%',
        duration: 0.5,
      });
    }
  }, [navOpen]);

  return (
    <Container navOpen={navOpen} ref={containerRef} onClick={toggleNav}>
      <SideBar ref={sidebarRef} onClick={ev => ev.stopPropagation()}>
        <SocialContainer>
          <Title>Social</Title>
          <NavList>
            <a rel="noopener" href="https://twitter.com/AppBrewhouse" target="_blank"><Target>Twitter</Target></a>
            <a rel="noopener" href="https://linkedin.com/company/app-brewhouse" target="_blank"><Target>Linkedin</Target></a>
            <a rel="noopener" href="https://github.com/appbrewhouse" target="_blank"><Target>Github</Target></a>
          </NavList>
        </SocialContainer>
        <MenuContainer>
          <Title>Menu</Title>
          <NavList className="menu">
            <PageTransition to='/'><Target>Home</Target></PageTransition>
            <PageTransition to='/works'><Target>Our Works</Target></PageTransition>
            <PageTransition to='/about'><Target>About Us</Target></PageTransition>
            <PageTransition to='/services'><Target>Services</Target></PageTransition>
            <PageTransition to='/contact'><Target>Contact Us</Target></PageTransition>
          </NavList>
        </MenuContainer>
      </SideBar>
    </Container>
  );
};

export default Navigation;
