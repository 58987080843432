import React from 'react';

import { Container } from './../styles/button';
import Target from './Cursor/target';

const Button: React.FC<any> = ({ children, ...rest }) => {
  const ariaLabel = typeof children === 'string' ? children : 'abh-button';
  return (
    <Target>
      <Container aria-label={ariaLabel} {...rest}>
        {children}
      </Container>
    </Target>
  );
}

export default Button;
