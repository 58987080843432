import React from 'react';
import TransitionLink, { TransitionPortal } from "gatsby-plugin-transition-link";
import gsap from 'gsap'

const ROOT_REF = "#___gatsby";
const ROOT_CONTENT_REF = "#layout-container";
const COVER_REF = "tl-cover-el"

const PageTransition = ({ children, length = 2, ...rest }) => {

  const animateExit = ({ props: { length: seconds }, node }) => {

    const wait = seconds / 6;
    const half = (seconds - wait) / 1.5;

    gsap.to(`.${COVER_REF}`, {
      y: '0',
      ease: "power3.easeOut",
      duration: half
    })

    gsap.to(ROOT_REF, {
      background: 'rgba(0,0,0,0.75)',
      ease: "expo.easeInOut",
      duration: half,
    })

    gsap.to(ROOT_CONTENT_REF, {
      y: '-40%',
      opacity: 0,
      ease: "expo.easeInOut",
      duration: half,
    })

    gsap.to(ROOT_CONTENT_REF, {
      y: '0%',
      opacity: 1,
      ease: "expo.easeInOut",
      delay: half,
      duration: 0.1,
    })

    gsap.to(ROOT_REF, {
      background: '#FFFFFF',
      ease: "expo.easeInOut",
      delay: half,
      duration: 0.1,
    })

    return;
  };

  return (
    <>
      <TransitionLink
        exit={{
          length: length,
          trigger: ({ exit, node }) => animateExit({ props: exit, node }),
        }}
        entry={{ delay: length / 1.5 }}
        {...rest}
      >
        {children}
      </TransitionLink>
      <TransitionPortal>
        <div
          className={COVER_REF}
          style={{
            position: "fixed",
            background: "#FFFFFF",
            pointerEvents: 'none',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            transform: "translateY(200%)"
          }}
        />
      </TransitionPortal>
    </>
  );
};

export default PageTransition;
