import styled from "styled-components"

export const CursorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  contain: layout style size;
  pointer-events: none;
  will-change: transform;
  transition: opacity 0.3s, color 0.4s;

  &:before {
    content: "";
    position: absolute;
    top: -24px;
    left: -24px;
    display: block;
    min-width: 48px;
    min-height: 48px;
    transform: scale(0);
    background: inherit;
    border-radius: 100%;
    transition: transform 0.3s ease-in-out, opacity 0.1s;
  }

  @media (max-width: 700px) {
    display: none;
  }

  &.hasText {
    &:before {
      transform: scale(1) !important;
      top: -45px;
      left: -45px;
      min-width: 90px;
      min-height: 90px;
    }
    span {
      position: relative;
      z-index: 100000000;
      text-align: center;
      display: inline-block;
      top: -45px;
      left: -45px;
      width: 90px;
      height: 90px;
      padding: 10px;
      line-height: 70px;
      color: #ffffff;
    }
  }

  &.isVisible {
    &:before {
      transform: scale(0.2);
    }

    &.isHovered {
      &:before {
        transform: scale(1.25);
      }
    }
  }

  &.isClicked {
    &:before {
      transform: scale(0.25);
    }

    &.isHovered {
      &:before {
        transform: scale(1.35);
      }
    }
  }
`
