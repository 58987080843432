import React from 'react';

import Button from "./button";
import PageTransition from "./page-transition";
import Target from "./Cursor/target";
import {
  FooterWrap, Container, TopSection,
  CenterSection,
  BottomSection,
  AddressSection,
  NavigationSection,
  Sitemap,
  SocialConnect,
  Logo,
  Address,
  Title,
  NavItems,
  Item
} from "../styles/footer";

const Bottom = () => (
  <BottomSection>
    <p>&copy; 2021. The Tabs & Spaces pvt ltd.</p>
  </BottomSection>
);

const Top = () => (
  <TopSection>
    <div className="left">
      <h1>Have an idea?</h1>
    </div>
    <div className="right">
      <p>Challenge us. We deliver impactful and innovative products using state of the art technology.</p>
      <PageTransition to="/contact">
        <Button invert>Talk to us !</Button>
      </PageTransition>
    </div>
  </TopSection>
);

const Center = () => (
  <CenterSection>
    <AddressSection>
      <Logo>Tabs & Spaces.</Logo>
      <Address>
        <p>We are the digital nomads.</p>
        <p>info@tabsandspaces.co</p>
        <p>is our virtual address.</p>
      </Address>
      <PageTransition to="/privacy"><Target>View our privacy policy</Target></PageTransition>
    </AddressSection>

    <NavigationSection>
      <Sitemap>
        <Title>
          Sitemap
        </Title>
        <NavItems>
          <Item><PageTransition to="/"><Target>Home</Target></PageTransition></Item>
          <Item><PageTransition to="/works"><Target>Works</Target></PageTransition></Item>
          <Item><PageTransition to="/about"><Target>About us</Target></PageTransition></Item>
          <Item><PageTransition to="/services"><Target>Our Services</Target></PageTransition></Item>
          <Item><PageTransition to="/contact"><Target>Contact us</Target></PageTransition></Item>
        </NavItems>
      </Sitemap>
      <SocialConnect>
        <Title>
          Social Connect
        </Title>
        <NavItems>
          <Item><a rel="noopener" href="https://twitter.com/TabsAndSpaces" target="_blank"><Target>Twitter</Target></a></Item>
          <Item><a rel="noopener" href="https://linkedin.com/company/tabs-and-spaces" target="_blank"><Target>Linkedin</Target></a></Item>
          <Item><a rel="noopener" href="https://github.com/tabsandspaces" target="_blank"><Target>Github</Target></a></Item>
        </NavItems>
      </SocialConnect>
    </NavigationSection>
  </CenterSection>
);

const Footer = () => {
  return (
    <FooterWrap>
      <Container>
        <Top />
        <Center />
        <Bottom />
      </Container>
    </FooterWrap>
  )
};

export default Footer;
