import React, { useEffect, useRef } from 'react';
import gsap from "gsap";

import {
  Container, LogoWrap, LetsTalkWrap, HamWrap,
  Hamburger,
  HamLine1,
  HamLine2
} from './../styles/pageItems';
import MagneticCursor from './magnetic-cursor';
import PageTransition from './page-transition';

import Logo from "./../svgs/logo.svg";
import LetsTalk from "./../svgs/letsTalk.svg";


const PageItems: React.FC<any> = ({ toggleNav }) => {
  const logoRef = useRef(null);
  const letsTalkRef = useRef(null);
  const hamRef = useRef(null);

  useEffect(() => {
    const animDuration = 0.75;
    const delay = 0.5;
    gsap.to(logoRef.current, {
      opacity: 1,
      duration: animDuration,
      delay: delay
    })
    gsap.to(letsTalkRef.current, {
      opacity: 1,
      duration: animDuration,
      delay: delay + 0.2
    })
    gsap.to(hamRef.current, {
      opacity: 1,
      duration: animDuration,
      delay: delay + 0.4
    })
  }, []);

  const openMailTo = () => {
    window.location.href = "mailto:info@appbrewhouse.com"
  }

  return (
    <Container>
      <LogoWrap ref={logoRef}>
        <PageTransition to="/">
          <MagneticCursor>
            <Logo />
          </MagneticCursor>
        </PageTransition>
      </LogoWrap>

      <LetsTalkWrap ref={letsTalkRef}>
        <MagneticCursor onClick={openMailTo}>
          <LetsTalk />
        </MagneticCursor>
      </LetsTalkWrap>

      <HamWrap ref={hamRef}>
        <MagneticCursor onClick={toggleNav}>
          <Hamburger>
            <HamLine1 />
            <HamLine2 />
          </Hamburger>
        </MagneticCursor>
      </HamWrap>
    </Container>
  )
};

export default PageItems;
