import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import classnames from 'classnames';

import hexTorgba from "../../helpers/hextorgba";
import { useGlobalStateContext } from "../../context/globalContext";
import { getGlobals } from './../../helpers/globals';
import { CursorContainer } from './../../styles/cursor';

export interface CursorProps {
  cursorRadius?: number;
  className?: string;
}

export const Cursor: React.FC<CursorProps> = ({ className, cursorRadius, children }) => {
  const { cursorType, cursorColor, cursorText } = useGlobalStateContext()
  const prefixClass = getGlobals('CLASSNAME_PREFIX');
  const cursorRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const onMouseLeave = () => setIsVisible(false);
  const onMouseEnter = () => setIsVisible(true);
  const onMouseMove = ({ clientX: x, clientY: y }) => cursorRef.current && gsap.to(cursorRef.current, { duration: 0.5, x, y });
  const onMouseDown = () => setIsClicked(true);
  const onMouseUp = () => setIsClicked(false);

  useEffect(() => {
    window.addEventListener('mouseout', onMouseLeave);
    window.addEventListener('mouseover', onMouseEnter);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);
    cursorRef.current && gsap.to(cursorRef.current, { duration: 0.5, backgroundColor: cursorColor })

    return () => {
      window.removeEventListener('mouseout', onMouseLeave);
      window.removeEventListener('mouseover', onMouseEnter);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    }
  }, []);

  const classname = classnames(`${prefixClass}-cursor-container`, className, {
    'isVisible': isVisible,
    'isClicked': isClicked,
    'isHovered': cursorType === 'hovered',
    'hasText': cursorText
  });

  return (
    <CursorContainer
      ref={cursorRef}
      id={`${prefixClass}-cursor-container`}
      style={{
        background: cursorText ? hexTorgba(cursorColor, 0.6): cursorColor,
        mixBlendMode: cursorColor === '#F5F5F5' ? 'difference' : 'normal'
      }}
      className={classname}>
      {cursorText ? <span>{cursorText}</span> : null}
    </CursorContainer>
  );
};

Cursor.defaultProps = {
  cursorRadius: 24,
}

export default Cursor;
