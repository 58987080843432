import styled from "styled-components"

export const Container = styled.div<any>`
  background: rgba(229, 229, 229, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  pointer-events: ${({ navOpen }) => (navOpen ? "all" : "none")};
`

export const SideBar = styled.div`
  width: 716px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #ffffff;
  display: flex;
  padding-top: 10%;
  transform: translateX(150%);

  @media (max-width: 599px) {
    width: 100vw;
  }
  @media (min-width: 600px) {
    width: 100vw;
  }
  @media (min-width: 900px) {
    width: 716px;
  }
`

export const Title = styled.h6`
  font-size: 18px;
  font-weight: 500;
  color: #979797;
  margin-bottom: 30px;
`

export const SocialContainer = styled.div`
  width: 250px;
  padding: 48px;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: block;
  }
  @media (min-width: 900px) {
    display: block;
  }
`
export const MenuContainer = styled.div`
  flex: 1;
  padding: 48px;
`

export const NavList = styled.nav`
  & > a {
    display: block;
    margin-bottom: 23px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  &.menu {
    & > a {
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 48px;

      &:active,
      &:hover,
      &:visited,
      &:link {
        color: #000;
        text-decoration: none;
      }
    }
  }
`
