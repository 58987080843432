import React from 'react';

import { Container } from '../styles/magnetic-cursor';
import Target from './Cursor/target';

const MagneticCursor: React.FC<any> = ({ children, ...rest }) => {
  const ariaLabel = typeof children === "string" ? children : 'abh-magcursor';
  return (
    <Target>
      <Container role="button" aria-label={ariaLabel} {...rest}>
        {children}
      </Container>
    </Target>
  );
};

export default MagneticCursor;
